import React, { FormEvent } from 'react'
import { Button, Grid, InputAdornment, TextField } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { MoneyIcon } from '../../icons'
import { LoadingBackdrop } from '../../components'
import { config } from '../../config'
import { InitializeDepositRequest } from '../../services/deposit/models'

import { useNavigate } from 'react-router-dom'
import { useDeposit } from '../../hooks/use-deposit/use-deposit'
import { useAuth } from '../../hooks'

export const DepositPage: React.FunctionComponent = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { maxDepositAmount, initializeDeposit, error, loading } = useDeposit()
  const { logout } = useAuth()

  React.useEffect(() => {
    if (error) {
      if (error.status === 401) {
        logout()
        navigate('/error')
      }
    }
  }, [error])

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (loading) {
      return
    }

    const formData = new FormData(e.target as HTMLFormElement)

    const amount = parseFloat(formData.get('amount') as string)

    const params: InitializeDepositRequest = {
      email: formData.get('email') as string,
      amount: amount * 100,
      redirectUrls: {
        success: window.location.origin + config.appRoutes.success,
        cancel: window.location.origin + config.appRoutes.cancel,
      },
    }

    initializeDeposit(params).then(res => {
      window.location.replace(res.data.href)
    })
  }

  return (
    <>
      <h2>{t('depositPage.title')}</h2>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label={t('common.email')}
              name='email'
              variant='outlined'
              required
              type='email'
              disabled={loading}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              name='amount'
              label={t('common.amount')}
              helperText={
                t('common.amount') +
                ' ' +
                t('common.below') +
                ' ' +
                maxDepositAmount +
                '€'
              }
              required
              type='number'
              variant='outlined'
              disabled={loading}
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <MoneyIcon opacity='0.25' />
                  </InputAdornment>
                ),
                endAdornment: <InputAdornment position='end'>€</InputAdornment>,
                inputProps: {
                  max: maxDepositAmount,
                  step: '0.01',
                },
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              color='primary'
              type='submit'
              variant='contained'
              disabled={loading}
            >
              {t('common.button.confirm')}
            </Button>
          </Grid>
        </Grid>
      </form>

      <LoadingBackdrop open={loading} />
    </>
  )
}

export default DepositPage
