import { Button } from '@material-ui/core'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { config } from '../../config'
import { useAuth } from '../../hooks/use-auth'

export const LoginPage: React.FunctionComponent = () => {
  const { t } = useTranslation()
  const { state, login } = useAuth()
  const navigate = useNavigate()

  React.useEffect(() => {
    if (state.isAuthenticated) {
      navigate(config.appRoutes.deposit, { replace: true })
    }
  }, [state.isAuthenticated])

  return (
    <>
      <h1>{t('loginPage.title')}</h1>
      <p>{t('loginPage.desc')}</p>
      <Button color='primary' onClick={login}>
        {t('common.button.authenticate')}
      </Button>
    </>
  )
}
