import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import HttpApi, { HttpBackendOptions } from 'i18next-http-backend'
import { initReactI18next } from 'react-i18next'
import { config } from './config'

i18n
  .use(HttpApi)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init<HttpBackendOptions>({
    debug: process.env.NODE_ENV === 'production' ? false : true,
    fallbackLng: 'fi',
    fallbackNS: 'translation',
    supportedLngs: config.i18n.supportedLngs,
  })

i18n.on('languageChanged', function (lng) {
  document.documentElement.setAttribute('lang', lng)
})

export default i18n
