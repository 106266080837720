import {
  AuthorizationNotifier,
  AuthorizationRequest,
  AuthorizationResponse,
  AuthorizationServiceConfiguration,
  BaseTokenRequestHandler,
  BasicQueryStringUtils,
  FetchRequestor,
  GRANT_TYPE_AUTHORIZATION_CODE,
  GRANT_TYPE_REFRESH_TOKEN,
  LocationLike,
  RedirectRequestHandler,
  RevokeTokenRequest,
  StringMap,
  TokenRequest,
  TokenResponse,
} from '@openid/appauth'
import { config as config2 } from '../../config'

class NoHashQueryStringUtils extends BasicQueryStringUtils {
  constructor() {
    super()
  }

  parse(input: LocationLike) {
    // never use hash
    return super.parse(input, false)
  }
}

type AuthorizationEvent = {
  request: AuthorizationRequest
  response: AuthorizationResponse
}

type AuthServiceConfiguration = {
  config: AuthorizationServiceConfiguration
}

export type TokenResponseWithRequiredFields = TokenResponse & {
  refreshToken: NonNullable<Pick<TokenResponse, 'refreshToken'>>
  expiresIn: NonNullable<Pick<TokenResponse, 'expiresIn'>>
  scope: NonNullable<Pick<TokenResponse, 'scope'>>
  tokenType: NonNullable<Pick<TokenResponse, 'tokenType'>>
}

export type RequestTokensWithRefreshTokenParameters =
  AuthServiceConfiguration & {
    refreshToken?: string
  }
export type RequestTokensWithCodeParameters = AuthServiceConfiguration & {
  code: string
  extras?: StringMap
}
export type LogoutParameters = AuthServiceConfiguration & {
  refreshToken: string
}

/**
 * Authentication Service
 *
 * Handles authentication related tasks like starting the login flow as need
 * and getting access tokens for backend calls.
 */
export class AuthService {
  constructor(private clientId: string) {}

  /**
   * Get authorization information
   *
   * Attempts to get the authorization info for further token requests.
   *
   * @returns Authorization request and response
   * @throws Throws on any error during authorization.
   */
  async getAuthorization(): Promise<AuthorizationEvent> {
    const notifier = new AuthorizationNotifier()
    const authorizationHandler = new RedirectRequestHandler(
      undefined,
      new NoHashQueryStringUtils(),
    )
    authorizationHandler.setAuthorizationNotifier(notifier)

    const authorizationEvent = new Promise<AuthorizationEvent>(
      (resolve, reject) => {
        notifier.setAuthorizationListener((req, res) => {
          if (res) {
            resolve({ request: req, response: res })
          } else {
            reject(new Error('Failed to get authorization response.'))
          }
        })
      },
    )

    // This will attempt to read authorization code from URL params and authorize.
    // completeAuthorizationRequestIfPossible() will return nothing if storage doesn't have correct keys
    await authorizationHandler.completeAuthorizationRequestIfPossible()
    const { request, response } = (await Promise.race([
      authorizationEvent,
      // The listener callback won't be called if request data is not present.
      // If the data is not present, then request is not initiated.
      // so this will force a resolution.
      new Promise((_resolve, reject) => {
        setTimeout(() => {
          reject(
            new Error(
              'Failed to read authorization request data or it was already used.',
            ),
          )
        }, 1000)
      }),
    ])) as AuthorizationEvent

    return { request, response }
  }

  /**
   * Request authorization from user
   *
   * This will send the user away from the current page in order to complete the auth flow!
   */
  async requestAuthorization({
    config,
  }: AuthServiceConfiguration): Promise<void> {
    const authorizationHandler = new RedirectRequestHandler(
      undefined,
      new NoHashQueryStringUtils(),
    )
    const request = new AuthorizationRequest({
      client_id: this.clientId,
      redirect_uri: config2.authRedirectURI,
      scope: 'offline',
      response_type: AuthorizationRequest.RESPONSE_TYPE_CODE,
      extras: { prompt: 'consent', access_type: 'offline', useNewAuth: '1' },
    })

    authorizationHandler.performAuthorizationRequest(config, request)
  }

  /**
   * Logout by revoking token and clearing memory
   *
   * @returns `true` on success, `false` on failure
   */
  async logout({ config, refreshToken }: LogoutParameters) {
    const tokenHandler = new BaseTokenRequestHandler(new FetchRequestor())
    const request = new RevokeTokenRequest({
      client_id: this.clientId,
      token: refreshToken,
    })

    const success = await tokenHandler.performRevokeTokenRequest(
      config,
      request,
    )

    return success
  }

  async requestTokensWithCode({
    config,
    code,
    extras,
  }: RequestTokensWithCodeParameters) {
    const tokenHandler = new BaseTokenRequestHandler(new FetchRequestor())
    const request = new TokenRequest({
      client_id: this.clientId,
      redirect_uri: config2.authRedirectURI,
      grant_type: GRANT_TYPE_AUTHORIZATION_CODE,
      code,
      extras,
    })

    return tokenHandler.performTokenRequest(
      config,
      request,
    ) as unknown as TokenResponseWithRequiredFields
  }

  async requestTokensWithRefreshToken({
    refreshToken,
    config,
  }: RequestTokensWithRefreshTokenParameters) {
    const tokenHandler = new BaseTokenRequestHandler(new FetchRequestor())
    const request = new TokenRequest({
      client_id: this.clientId,
      redirect_uri: config2.authRedirectURI,
      grant_type: GRANT_TYPE_REFRESH_TOKEN,
      refresh_token: refreshToken,
    })

    return tokenHandler.performTokenRequest(
      config,
      request,
    ) as unknown as TokenResponseWithRequiredFields
  }

  /**
   * Get authorization service configuration either from memory or from the server
   *
   * @returns Authorization server configuration
   */
  public async requestServiceConfiguration(): Promise<AuthorizationServiceConfiguration> {
    return AuthorizationServiceConfiguration.fetchFromIssuer(
      config2.smartumApiURL,
      new FetchRequestor(),
    )
  }
}

export default AuthService
