import { makeStyles, Theme } from '@material-ui/core'
import { config } from '../../config'

export interface StyleProps {
  pathname?: string
}

export const useStyles = makeStyles<Theme, StyleProps>(theme => ({
  appBarRoot: {
    height: '50vh',
    backgroundImage: 'linear-gradient(#d3d3d3, #efefef)',
  },
  toolbarRoot: {
    justifyContent: 'space-between',
    padding: 16,
  },
  lngSelectFilled: {
    paddingTop: 10,
    backgroundColor: '#ffffff',
    borderRadius: 6,
  },
  heroImage: {
    height: '100%',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundImage: getMobileBgImage,
    [theme.breakpoints.up('sm')]: {
      backgroundImage: getDesktopBgImage,
    },
  },
}))

const getMobileBgImage = ({ pathname }: StyleProps) => {
  switch (pathname) {
    case config.appRoutes.success:
      return 'url(assets/SmartumWallet_payment-success--mobile.png)'
    case config.appRoutes.cancel:
      return 'url(assets/SmartumWallet_payment-error--mobile.png)'
    default:
      return 'url(assets/SmartumWallet_hero-mobile.png)'
  }
}

const getDesktopBgImage = ({ pathname }: StyleProps) => {
  switch (pathname) {
    case config.appRoutes.success:
      return 'url(assets/SmartumWallet_payment-success--desktop.png)'
    case config.appRoutes.cancel:
      return 'url(assets/SmartumWallet_payment-error--desktop.png)'
    default:
      return 'url(assets/SmartumWallet_hero-desktop.png)'
  }
}
