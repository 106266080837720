import { Backdrop, BackdropProps, CircularProgress } from '@material-ui/core'
import React from 'react'
import { useStyles } from './loading-backdrop.styles'

export const LoadingBackdrop: React.FunctionComponent<
  BackdropProps
> = props => {
  const classes = useStyles()

  return (
    <Backdrop classes={classes} {...props}>
      <CircularProgress size='25vw' />
    </Backdrop>
  )
}
