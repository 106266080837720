import { SvgIcon, SvgIconProps } from '@material-ui/core'
import React from 'react'

export const MoneyIcon: React.FunctionComponent<SvgIconProps> = props => {
  return (
    <SvgIcon viewBox='0 0 17 17' {...props}>
      <path
        d='M.85 0C.38 0 0 .38 0 .85v8.5c0 .47.38.85.85.85h15.3c.47 0 .85-.38.85-.85V.85c0-.47-.38-.85-.85-.85H.85zm1.625 1.7h12.05c.128.362.413.647.775.775v5.25c-.362.128-.647.413-.775.775H2.475c-.128-.362-.413-.647-.775-.775v-5.25c.362-.128.647-.413.775-.775zm6.025.85c-1.408 0-2.55 1.142-2.55 2.55S7.092 7.65 8.5 7.65s2.55-1.142 2.55-2.55S9.908 2.55 8.5 2.55zm-4.25 1.7c-.47 0-.85.38-.85.85s.38.85.85.85.85-.38.85-.85-.38-.85-.85-.85zm8.5 0c-.47 0-.85.38-.85.85s.38.85.85.85.85-.38.85-.85-.38-.85-.85-.85zM1.692 11.9l12.952 3.373c.455.119.918-.155 1.036-.609l.72-2.764h-1.756l-.2.765c-.382.033-.727.237-.942.553L8.44 11.9H1.692z'
        transform='translate(-74 -462) translate(53 380) translate(0 68) translate(21 14)'
      />
    </SvgIcon>
  )
}
