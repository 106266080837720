import { AppBar, Link, Toolbar } from '@material-ui/core'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { config } from '../../config'
import { useAuth } from '../../hooks/use-auth'
import { useStyles } from './app-footer.styles'

export const AppFooter: React.FunctionComponent = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const classes = useStyles()
  const { state, logout } = useAuth()

  return (
    <AppBar
      component='footer'
      color='transparent'
      elevation={0}
      classes={{ root: classes.appBarRoot }}
    >
      <Toolbar classes={{ root: classes.toolbarRoot }}>
        <Link
          href='https://www.smartum.fi/'
          target='_blank'
          rel='noopener noreferrer'
        >
          smartum.fi
        </Link>
        <Link
          href='https://support.smartum.fi/'
          target='_blank'
          rel='noopener noreferrer'
        >
          {t('appFooter.link.customerSupport')}
        </Link>
        {state.isAuthenticated && (
          <Link
            onClick={async () => {
              await logout()
              navigate(config.appRoutes.login)
            }}
          >
            {t('appFooter.link.logout')}
          </Link>
        )}
      </Toolbar>
    </AppBar>
  )
}
