import { CssBaseline, ThemeProvider } from '@material-ui/core'
import React, { Suspense } from 'react'
import { createRoot } from 'react-dom/client'
import { BrowserRouter as Router } from 'react-router-dom'
import App from './App'
import { AuthProvider } from './hooks/use-auth/use-auth'
import './i18n'
import { theme } from './theme'
import { storage } from './services/storage'

if (process.env.NODE_ENV === 'development') {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const { worker } = require('./mocks/browser')
  worker.start()
}

const root = createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <Suspense fallback={null}>
          <AuthProvider storage={storage}>
            <App />
          </AuthProvider>
        </Suspense>
      </Router>
    </ThemeProvider>
  </React.StrictMode>,
)
