import React from 'react'
import ApiService from '../../services/deposit'
import {
  HttpError,
  InitializeDepositResponse,
} from '../../services/deposit/models'
import { useAuth } from '../use-auth'

export type UseDepositReturnProps = {
  loading: boolean
  maxDepositAmount: number
  initializeDeposit: (
    ...parameters: Parameters<typeof ApiService.prototype.initializeDeposit>
  ) => Promise<InitializeDepositResponse>
  error?: HttpError
}

export interface UseDepositProps {
  maxDepositAmount?: number
}

export const useDeposit = (
  props: UseDepositProps = {},
): UseDepositReturnProps => {
  const { maxDepositAmount: initialMaxDepositAmount = 100 } = props
  const [error, setError] = React.useState(undefined)
  const [loading, setLoading] = React.useState(false)
  const [maxDepositAmount, setMaxDepositAmount] = React.useState<number>(
    initialMaxDepositAmount,
  )
  const { getAccessToken } = useAuth()

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const depositService = new ApiService(getAccessToken())

  React.useEffect(() => {
    setLoading(true)
    const maximumDepositAmount = async () => {
      depositService
        .getMaximumDepositAmount()
        .then(response => {
          setMaxDepositAmount(response.data.maxDepositAmount / 100)
        })
        .catch(error => {
          setError(error)
        })
        .finally(() => {
          setLoading(false)
        })
    }

    maximumDepositAmount()
  }, [setMaxDepositAmount])

  const initializeDeposit = (
    ...parameters: Parameters<typeof ApiService.prototype.initializeDeposit>
  ) => {
    return new Promise<InitializeDepositResponse>((resolve, reject) => {
      setLoading(true)

      depositService
        .initializeDeposit(...parameters)
        .then(resolve)
        .catch(error => {
          setError(error)
          reject(error)
        })
        .finally(() => {
          setLoading(false)
        })
    })
  }

  return { loading, error, maxDepositAmount, initializeDeposit }
}

export default useDeposit
