import { config } from '../../config'
import {
  FinalizeDepositPaymentRequest,
  FinalizeDepositPaymentResponse,
  MaximumDepositAmountResponse,
  HttpError,
  InitializeDepositPaymentRequest,
  InitializeDepositPaymentResponse,
  InitializeDepositRequest,
  InitializeDepositResponse,
} from './models'

export class ApiService {
  constructor(private accessToken: string) {}

  async initializeDepositPayment(
    payload: InitializeDepositPaymentRequest,
  ): Promise<InitializeDepositPaymentResponse> {
    const request = new Request(
      `${config.smartumPlusApiURL}/deposit/initialize`,
      {
        method: 'POST',
        body: JSON.stringify(payload),
      },
    )

    return this.makeRequest(request)
  }

  async finalizeDepositPayment(
    payload: FinalizeDepositPaymentRequest,
  ): Promise<FinalizeDepositPaymentResponse> {
    const request = new Request(
      `${config.smartumPlusApiURL}/deposit/finalize`,
      {
        method: 'POST',
        body: JSON.stringify(payload),
      },
    )

    return this.makeRequest(request)
  }

  async getMaximumDepositAmount(): Promise<MaximumDepositAmountResponse> {
    const request = new Request(
      `${config.smartumApiURL}/deposit/maxDepositAmount`,
      {
        method: 'GET',
      },
    )

    return this.makeRequest(request)
  }

  private async makeRequest<T>(request: Request): Promise<T> {
    request = await this.setCommonHeaders(request)
    const response = await fetch(request)

    if (response.ok) {
      return response.json()
    } else {
      throw new HttpError(response.statusText, response.status, response)
    }
  }

  private async setCommonHeaders(request: Request): Promise<Request> {
    const headers = new Headers()
    headers.append('Content-Type', 'application/json')
    headers.append('Authorization', `Bearer ${this.accessToken}`)

    return new Request(request, { headers })
  }

  // New implementation
  async initializeDeposit(
    payload: InitializeDepositRequest,
  ): Promise<InitializeDepositResponse> {
    const request = new Request(`${config.smartumApiURL}/deposit/initialize`, {
      method: 'POST',
      body: JSON.stringify(payload),
    })
    return this.makeRequest(request)
  }
}

export default ApiService
