export class HttpError extends Error {
  readonly name = 'HttpError'

  constructor(
    message: string,
    public status: number,
    public response: Response,
  ) {
    super(message)
  }
}

export type PaytrailParams = { [key: string]: string }

export interface InitializeDepositPaymentRequest {
  /** Payment amount in cents. */
  amount: number
  successURL: string
  cancelURL: string
}

export interface InitializeDepositPaymentResponse {
  data: {
    paytrailParams: PaytrailParams
  }
}

export interface FinalizeDepositPaymentRequest {
  paytrailParams: PaytrailParams
}

export interface FinalizeDepositPaymentResponse {
  data: null
}

export interface InitializeDepositRequest {
  email: string
  amount: number
  redirectUrls: {
    success: string
    cancel: string
  }
}

export interface InitializeDepositResponse {
  data: {
    transactionId: string
    href: string
    reference: string
  }
}

export interface MaximumDepositAmountResponse {
  data: {
    maxDepositAmount: number
  }
}
