interface AppConfig {
  environment: 'development' | 'staging' | 'production'
  smartumApiURL: string
  smartumPlusApiURL: string
  authRedirectURI: string
  appRoutes: {
    error: string
    deposit: string
    success: string
    cancel: string
    login: string
  }
  i18n: {
    supportedLngs: string[]
  }
}

const sharedConfig: AppConfig = {
  environment: 'development',
  smartumApiURL: 'https://api.staging.smartum.fi',
  smartumPlusApiURL: 'https://api.plus.staging.smartum.fi',
  authRedirectURI: 'http://localhost:3001/',
  appRoutes: {
    error: '/error',
    deposit: '/',
    success: '/success',
    cancel: '/cancel',
    login: '/login',
  },
  i18n: {
    supportedLngs: ['fi', 'en'],
  },
}

const localConfig: AppConfig = {
  ...sharedConfig,
}

const stagingConfig: AppConfig = {
  ...sharedConfig,
  environment: 'staging',
  smartumApiURL: 'https://api.staging.smartum.fi',
  smartumPlusApiURL: 'https://api.plus.staging.smartum.fi',
  authRedirectURI: 'https://wallet.staging.smartum.fi/',
}

const productionConfig: AppConfig = {
  ...sharedConfig,
  environment: 'production',
  smartumApiURL: 'https://api.smartum.fi',
  smartumPlusApiURL: 'https://api.plus.smartum.fi',
  authRedirectURI: 'https://wallet.smartum.fi/',
}

export const config = (() => {
  switch (window.location.hostname) {
    case 'wallet.smartum.fi':
      return productionConfig
    case 'wallet.staging.smartum.fi':
      return stagingConfig
    default:
      return localConfig
  }
})()
