import { AppBar, Box, MenuItem, Select, Toolbar } from '@material-ui/core'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { config } from '../../config'
import { useStyles } from './app-header.styles'

export const AppHeader: React.FunctionComponent = () => {
  const { t, i18n } = useTranslation()
  const location = useLocation()
  const classes = useStyles({
    pathname: location.pathname,
  })

  const handleLanguageSelect = (lng: string) => {
    i18n.changeLanguage(lng)
  }

  return (
    <AppBar
      position='static'
      color='transparent'
      elevation={0}
      classes={{ root: classes.appBarRoot }}
    >
      <Toolbar classes={{ root: classes.toolbarRoot }}>
        <img src='assets/smartum-logo.svg' alt='Smartum logo' />
        <Select
          value={i18n.language}
          onChange={e => handleLanguageSelect(e.target.value as string)}
          variant='filled'
          classes={{ filled: classes.lngSelectFilled }}
          disableUnderline
        >
          {config.i18n.supportedLngs.map(lng => (
            <MenuItem key={lng} value={lng}>
              {/* t('common.language.fi') */}
              {/* t('common.language.en') */}
              {t(`common.language.${lng}`)}
            </MenuItem>
          ))}
        </Select>
      </Toolbar>
      <Box className={classes.heroImage} />
    </AppBar>
  )
}
