import React from 'react'
import { Navigate, RouteProps, Outlet } from 'react-router-dom'
import { config } from '../../config'
import { useAuth } from '../../hooks/use-auth'

export const AuthComponent: React.FunctionComponent<RouteProps> = () => {
  const { state, completeAuthorizationFlow } = useAuth()

  React.useEffect(() => {
    ;(async () => {
      try {
        await completeAuthorizationFlow()
      } catch (error) {
        // AppAuth can send an error about the request.
        // There is also a possibility this will catch an error that request data was already used.
        // This happens because useEffect is triggered twice by React.StricMode. This won't happen in production.
        // it's a small issue. I could not find the culprit but it doesn't affect the application's health drastically.
        // TODO: log these
      }
    })()
  })

  return state.isAuthenticated ? (
    <Outlet />
  ) : (
    <Navigate replace to={config.appRoutes.login + location.search} />
  )
}
