import { createTheme } from '@material-ui/core'
import { fiFI } from '@material-ui/core/locale'

export const theme = createTheme(
  {
    palette: {
      primary: {
        main: '#ff3819',
      },
    },
    typography: {
      fontFamily: 'Work Sans',
    },
    overrides: {
      MuiContainer: {
        root: {
          paddingLeft: 53,
          paddingRight: 53,
        },
      },
      MuiButton: {
        root: {
          textTransform: 'none',
          fontWeight: 600,
          height: 46,
          borderRadius: 23,
        },
        label: {
          paddingLeft: 15,
          paddingRight: 15,
        },
      },
      MuiTextField: {
        root: {
          '& .MuiOutlinedInput-root': {
            borderRadius: 28,
          },
          '& .MuiOutlinedInput-adornedStart': {
            paddingLeft: 24,
          },
          '& .MuiOutlinedInput-adornedEnd': {
            paddingRight: 24,
          },
          '& .MuiOutlinedInput-input': {
            textAlign: 'center',
            fontWeight: 600,
          },
        },
      },
      MuiLink: {
        root: {
          marginLeft: 16,
          fontSize: '0.75rem',
          color: '#c7c7c7',
        },
      },
    },
    props: {
      MuiButton: {
        variant: 'contained',
      },
    },
  },
  fiFI,
)
