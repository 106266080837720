import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles({
  appBarRoot: {
    position: 'static',
    marginTop: 'auto',
  },
  toolbarRoot: {
    justifyContent: 'center',
  },
})
