import { Container } from '@material-ui/core'
import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import { AppFooter, AppHeader, AuthComponent } from './components'
import { config } from './config'
import { DepositPage, LoginPage, PaytrailResultPage, ErrorPage } from './pages'

function App(): JSX.Element {
  return (
    <>
      <AppHeader />

      <Container maxWidth='sm'>
        <Routes>
          <Route path={config.appRoutes.login} element={<LoginPage />} />
          {[config.appRoutes.success, config.appRoutes.cancel].map(
            (path, index) => {
              return (
                <Route
                  path={path}
                  element={<PaytrailResultPage />}
                  key={index}
                />
              )
            },
          )}

          <Route element={<AuthComponent />}>
            <Route path={config.appRoutes.deposit} element={<DepositPage />} />
          </Route>

          {/* Error page */}
          <Route path={config.appRoutes.error} element={<ErrorPage />} />

          {/* Route any other path to default */}
          <Route
            path='*'
            element={<Navigate replace to={config.appRoutes.deposit} />}
          />
        </Routes>
      </Container>

      <AppFooter />
    </>
  )
}

export default App
